export const FirebaseConfig = {
	"projectId": "ventarides-e2487",
	"appId": "1:193214003419:web:53d268b803c08a0922b4fe",
	"databaseURL": "https://ventarides-e2487-default-rtdb.firebaseio.com",
	"storageBucket": "ventarides-e2487.appspot.com",
	"locationId": "us-east1",
	"apiKey": "AIzaSyA3aTIyq2LUyyDHuUMqAcUFymE4ofuHBZc",
	"authDomain": "ventarides-e2487.firebaseapp.com",
	"messagingSenderId": "193214003419",
	"measurementId": "G-LCCZRYL7NE"
};